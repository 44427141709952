<template>
  <div>
    <b-modal
      ref="my-modal"
      centered
      modal-class="modal-warning"
      hide-footer
      :title="$t('Pages.Dashboard.Title.account_upgrade')"
    >
      <b-card-text>
        {{ $t("Pages.Dashboard.Title.upgrade_to_access") }}
        <br /><br />
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="upgrade"
        >
          {{ $t("Pages.Dashboard.Title.Upgrade") }}
        </b-button>
      </b-card-text>
    </b-modal>
    <b-row class="match-height">
      <b-col lg="8" sm="12" class="mb-0">
        <b-overlay
          :show="!chartjsData.lineChart.status"
          spinner-variant="primary"
          variant="transparent"
          spinner-type="grow"
          spinner-small
          rounded="sm"
        >
          <b-card no-body v-if="!chartjsData.lineChart.status">
            <b-card-header>
              <div>
                <b-card-title class="mb-1"
                  >${{ chartjsData.lineChart.total }}</b-card-title
                >
                <b-card-sub-title>{{
                  $t("Pages.Dashboard.Title.Income")
                }}</b-card-sub-title>
              </div>
            </b-card-header>

            <!-- chart -->
            <b-card-body>
              <chartjs-component-line-chart
                :height="430"
                :data="chartjsData.lineChart.data"
                :options="chartjsData.lineChart.options"
                :plugins="plugins"
              />
            </b-card-body>
          </b-card>
        </b-overlay>
        <b-card
          no-body
          v-if="chartjsData.lineChart.status"
          id="chart_Income"
          class="mb-1"
        >
          <b-card-header>
            <div>
              <b-card-title class="mb-1"
                >€{{ chartjsData.lineChart.total }}</b-card-title
              >
              <b-card-sub-title>{{
                $t("Pages.Dashboard.Title.Income")
              }}</b-card-sub-title>
            </div>
          </b-card-header>

          <!-- chart -->
          <b-card-body>
            <chartjs-component-line-chart
              :height="500"
              :data="chartjsData.lineChart.data"
              :options="chartjsData.lineChart.options"
              :plugins="plugins"
            />
          </b-card-body>
        </b-card>
      </b-col>
      <b-col
        lg="4"
        sm="12"
        class="mb-0"
        :style="
          $i18n.locale == 'fa'
            ? 'padding-right: 0.11rem; padding-left: 1rem;'
            : 'padding-right: 1rem; padding-left: 0.11rem;'
        "
      >
        <b-card
          class="card-congratulation-medal mb-1"
          style="max-height: 130px"
        >
          <b-row>
            <b-col cols="8">
              <h5>
                {{
                  $t("Pages.Dashboard.Title.have_premium {membership}", {
                    membership: plan.title,
                  })
                }}
              </h5>
              <b-card-text class="font-small-3">
                {{
                  $t("Pages.Dashboard.Title.{count_day} day_end", {
                    count_day: plan.days,
                  })
                }}
              </b-card-text>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="upgrade"
                v-if="plan.id != 1"
              >
                {{ $t("Pages.Dashboard.Title.account_upgrade") }}
              </b-button>
            </b-col>
            <b-col cols="4">
              <b-img
                :src="plan.icon"
                class="max-width max-height"
                alt="Medal Pic"
              />
            </b-col>
          </b-row>
        </b-card>
        <b-overlay
          :show="!showNews"
          spinner-variant="primary"
          variant="transparent"
          spinner-type="grow"
          spinner-small
          blur="1rem"
          rounded="sm"
        >
          <b-card v-if="!showNews" no-body class="mb-1">
            <b-card-header class="d-flex justify-content-between">
              <b-card-title class="ml-25">
                {{ $t("Pages.Dashboard.Title.important_news") }}
              </b-card-title>
              <b-button
                variant="primary"
                size="sm"
                @click="showAllNotifications"
              >
                {{ $t("Pages.Dashboard.Button.show_all") }}
              </b-button>
            </b-card-header>

            <!-- timeline -->
            <b-card-body class="card-fix-size mb-1 pb-2">
              <app-timeline>
                <app-timeline-item
                  v-for="news in data.userNews"
                  :key="news.id"
                  :variant="news.type == 'single' ? 'danger' : 'warning'"
                >
                  <div
                    class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1"
                  >
                    <h6>{{ news.title }}</h6>
                    <small
                      class="timeline-item-time text-nowrap text-muted ml-1"
                      >{{ MyDate.getFormatDate(news.created_at) }}</small
                    >
                  </div>
                  <p>{{ news.subtitle }}</p>
                </app-timeline-item>
              </app-timeline>
            </b-card-body>
          </b-card>
        </b-overlay>
        <!-- ///////notifications list//// -->
        <b-card v-if="showNews" no-body id="news" class="mb-1">
          <b-card-header class="d-flex justify-content-between">
            <b-card-title class="ml-25">
              {{ $t("Pages.Dashboard.Title.important_news") }}
            </b-card-title>
            <b-button variant="primary" size="sm" @click="showAllNotifications">
              {{ $t("Pages.Dashboard.Button.show_all") }}
            </b-button>
          </b-card-header>

          <!-- timeline -->
          <b-card-body class="card-fix-size">
            <app-timeline>
              <app-timeline-item
                v-for="news in data.userNews"
                :key="news.id"
                :variant="news.type == 'single' ? 'danger' : 'warning'"
              >
                <div
                  class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1"
                >
                  <h6>{{ news.title }}</h6>
                  <small
                    class="timeline-item-time text-nowrap text-muted ml-1"
                    >{{ MyDate.getFormatDate(news.created_at) }}</small
                  >
                </div>
                <p>{{ news.subtitle }}</p>
              </app-timeline-item>
            </app-timeline>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="6" sm="12" class="mb-1">
        <swiper
          v-if="masterTube_banners"
          class="swiper-autoplay"
          :options="swiperOptions"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        >
          <swiper-slide key="1">
            <b-link
              v-if="masterTube_banners.smart_youtuber"
              target="blank"
              href="https://master.tube/course/smart-youtuber/"
            >
              <b-img
                :src="masterTube_banners.smart_youtuber"
                style="width: 100%; height: auto"
                class="rounded"
              >
              </b-img>
            </b-link>
          </swiper-slide>
          <swiper-slide key="2">
            <b-link :to="{ path: '/city' }">
              <b-img
                :src="masterTube_banners.master_city"
                style="width: 100%; height: auto"
                class="rounded"
              >
              </b-img>
            </b-link>
          </swiper-slide>
          <swiper-slide key="3">
            <b-link target="blank" href="https://master.tube">
              <b-img
                :src="masterTube_banners.website_banner"
                style="width: 100%; height: auto"
                class="rounded"
              >
              </b-img>
            </b-link>
          </swiper-slide>
        </swiper>
      </b-col>
      <b-col lg="6" sm="12" class="mb-1">
        <swiper
          v-if="masterTube_banners"
          class="swiper-autoplay"
          :options="swiperOptions"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        >
          <swiper-slide key="1">
            <b-link :to="{ path: '/channels' }">
              <b-img
                :src="masterTube_banners.no_vpn"
                style="width: 100%; height: auto"
                class="rounded"
              >
              </b-img>
            </b-link>
          </swiper-slide>
          <swiper-slide key="2">
            <b-link :to="{ path: '/tools/assistant/reviews' }">
              <b-img
                :src="masterTube_banners.assistant"
                style="width: 100%; height: auto"
                class="rounded"
              >
              </b-img>
            </b-link>
          </swiper-slide>
          <swiper-slide key="3">
            <b-link :to="{ name: 'masterFile' }">
              <b-img
                :src="masterTube_banners.master_file"
                style="width: 100%; height: auto"
                class="rounded"
              >
              </b-img>
            </b-link>
          </swiper-slide>
        </swiper>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="3" sm="6">
        <b-overlay
          :show="!data.views.status"
          spinner-variant="primary"
          variant="transparent"
          spinner-type="grow"
          spinner-small
          blur="1rem"
          rounded="sm"
        >
          <statistic-card-with-area-chart
            v-if="!data.views.status"
            icon="GlobeIcon"
            :statistic="kFormatter(data.views.analyticsData)"
            :statistic-title="$t('Pages.Dashboard.Title.View')"
            :chart-data="data.views.series"
          />
        </b-overlay>
        <statistic-card-with-area-chart
          v-if="data.views.status"
          icon="GlobeIcon"
          :statistic="kFormatter(data.views.analyticsData)"
          :statistic-title="$t('Pages.Dashboard.Title.View')"
          :chart-data="data.views.series"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
        :style="
          $i18n.locale == 'fa'
            ? 'padding-right: 0.11rem; padding-left: 1rem;'
            : 'padding-right: 1rem; padding-left: 0.11rem;'
        "
      >
        <b-overlay
          :show="!data.watchtime.status"
          spinner-variant="primary"
          variant="transparent"
          spinner-type="grow"
          spinner-small
          blur="1rem"
          rounded="sm"
        >
          <statistic-card-with-area-chart
            v-if="!data.watchtime.status"
            icon="WatchIcon"
            color="warning"
            :statistic="kFormatter(data.watchtime.analyticsData)"
            :statistic-title="$t('Pages.Dashboard.Title.watch_time')"
            :chart-data="data.watchtime.series"
          />
        </b-overlay>
        <statistic-card-with-area-chart
          v-if="data.watchtime.status"
          icon="WatchIcon"
          color="warning"
          :statistic="kFormatter(data.watchtime.analyticsData)"
          :statistic-title="$t('Pages.Dashboard.Title.watch_time')"
          :chart-data="data.watchtime.series"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
        :style="
          $i18n.locale == 'fa'
            ? 'padding-right: 0.11rem; padding-left: 1rem;'
            : 'padding-right: 1rem; padding-left: 0.11rem;'
        "
      >
        <b-overlay
          :show="!data.subscribers.status"
          spinner-variant="primary"
          variant="transparent"
          spinner-type="grow"
          spinner-small
          blur="1rem"
          rounded="sm"
        >
          <statistic-card-with-area-chart
            v-if="!data.subscribers.status"
            icon="UserPlusIcon"
            color="success"
            :statistic="kFormatter(data.subscribers.analyticsData)"
            :statistic-title="$t('Pages.Dashboard.Title.Subscriber')"
            :chart-data="data.subscribers.series"
          />
        </b-overlay>
        <statistic-card-with-area-chart
          v-if="data.subscribers.status"
          icon="UserPlusIcon"
          color="success"
          :statistic="kFormatter(data.subscribers.analyticsData)"
          :statistic-title="$t('Pages.Dashboard.Title.Subscriber')"
          :chart-data="data.subscribers.series"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
        :style="
          $i18n.locale == 'fa'
            ? 'padding-right: 0.11rem; padding-left: 1rem;'
            : 'padding-right: 1rem; padding-left: 0.11rem;'
        "
      >
        <b-overlay
          :show="!data.subscriberslost.status"
          spinner-variant="primary"
          variant="transparent"
          spinner-type="grow"
          spinner-small
          blur="1rem"
          rounded="sm"
        >
          <statistic-card-with-area-chart
            v-if="!data.subscriberslost.status"
            icon="UserMinusIcon"
            color="danger"
            :statistic="kFormatter(data.subscriberslost.analyticsData)"
            :statistic-title="$t('Pages.Dashboard.Title.missed_sub')"
            :chart-data="data.subscriberslost.series"
          />
        </b-overlay>
        <statistic-card-with-area-chart
          v-if="data.subscriberslost.status"
          icon="UserMinusIcon"
          color="danger"
          :statistic="kFormatter(data.subscriberslost.analyticsData)"
          :statistic-title="$t('Pages.Dashboard.Title.missed_sub')"
          :chart-data="data.subscriberslost.series"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="3" sm="6">
        <b-overlay
          :show="!data.like.status"
          spinner-variant="primary"
          variant="transparent"
          spinner-type="grow"
          spinner-small
          blur="1rem"
          rounded="sm"
        >
          <statistic-card-with-area-chart
            v-if="!data.like.status"
            icon="ThumbsUpIcon"
            color="success"
            :statistic="kFormatter(data.like.analyticsData)"
            :statistic-title="$t('Pages.Dashboard.Title.Like')"
            :chart-data="data.like.series"
          />
        </b-overlay>
        <statistic-card-with-area-chart
          v-if="data.like.status"
          icon="ThumbsUpIcon"
          color="success"
          :statistic="kFormatter(data.like.analyticsData)"
          :statistic-title="$t('Pages.Dashboard.Title.Like')"
          :chart-data="data.like.series"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
        :style="
          $i18n.locale == 'fa'
            ? 'padding-right: 0.11rem; padding-left: 1rem;'
            : 'padding-right: 1rem; padding-left: 0.11rem;'
        "
      >
        <b-overlay
          :show="!data.dislike.status"
          spinner-variant="primary"
          variant="transparent"
          spinner-type="grow"
          spinner-small
          blur="1rem"
          rounded="sm"
        >
          <statistic-card-with-area-chart
            v-if="!data.dislike.status"
            icon="ThumbsDownIcon"
            color="danger"
            :statistic="kFormatter(data.dislike.analyticsData)"
            :statistic-title="$t('Pages.Dashboard.Title.Dislike')"
            :chart-data="data.dislike.series"
          />
        </b-overlay>
        <statistic-card-with-area-chart
          v-if="data.dislike.status"
          icon="ThumbsDownIcon"
          color="danger"
          :statistic="kFormatter(data.dislike.analyticsData)"
          :statistic-title="$t('Pages.Dashboard.Title.Dislike')"
          :chart-data="data.dislike.series"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
        :style="
          $i18n.locale == 'fa'
            ? 'padding-right: 0.11rem; padding-left: 1rem;'
            : 'padding-right: 1rem; padding-left: 0.11rem;'
        "
      >
        <b-overlay
          :show="!data.share.status"
          spinner-variant="primary"
          variant="transparent"
          spinner-type="grow"
          spinner-small
          blur="1rem"
          rounded="sm"
        >
          <statistic-card-with-area-chart
            v-if="!data.share.status"
            icon="Share2Icon"
            :statistic="kFormatter(data.share.analyticsData)"
            :statistic-title="$t('Pages.Dashboard.Title.Share')"
            :chart-data="data.share.series"
          />
        </b-overlay>
        <statistic-card-with-area-chart
          v-if="data.share.status"
          icon="Share2Icon"
          :statistic="kFormatter(data.share.analyticsData)"
          :statistic-title="$t('Pages.Dashboard.Title.Share')"
          :chart-data="data.share.series"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
        :style="
          $i18n.locale == 'fa'
            ? 'padding-right: 0.11rem; padding-left: 1rem;'
            : 'padding-right: 1rem; padding-left: 0.11rem;'
        "
      >
        <b-overlay
          :show="!data.comment.status"
          spinner-variant="primary"
          variant="transparent"
          spinner-type="grow"
          spinner-small
          blur="1rem"
          rounded="sm"
        >
          <statistic-card-with-area-chart
            v-if="!data.comment.status"
            icon="MessageSquareIcon"
            color="warning"
            :statistic="kFormatter(data.comment.analyticsData)"
            :statistic-title="$t('Pages.Dashboard.Title.Comment')"
            :chart-data="data.comment.series"
          />
        </b-overlay>
        <statistic-card-with-area-chart
          v-if="data.comment.status"
          icon="MessageSquareIcon"
          color="warning"
          :statistic="kFormatter(data.comment.analyticsData)"
          :statistic-title="$t('Pages.Dashboard.Title.Comment')"
          :chart-data="data.comment.series"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BCardText,
  BLink,
  BCardHeader,
  BCardBody,
  BCardSubTitle,
  BCardTitle,
  BImg,
  BButton,
  VBTooltip,
  BModal,
  BOverlay,
} from "bootstrap-vue";
import ChartjsComponentLineChart from "../charts/charts-components/ChartjsComponentLineChart.vue";
import StatisticCardWithAreaChart from "@core/components/statistics-cards/StatisticCardWithAreaChart.vue";
import { kFormatter } from "@core/utils/filter";
import chartjsData from "../charts/chartjsData";
import Ripple from "vue-ripple-directive";
import MyDate from "@core/components/date/MyDate.vue";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import { langInfoDao } from "@/daos/languages/langInfoDao";

export default {
  components: {
    StatisticCardWithAreaChart,
    BCard,
    BCol,
    BImg,
    BRow,
    BButton,
    BCardHeader,
    BCardBody,
    BCardSubTitle,
    BCardTitle,
    ChartjsComponentLineChart,
    BCardText,
    BLink,
    AppTimeline,
    AppTimelineItem,
    BModal,
    BOverlay,
    Swiper,
    SwiperSlide,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      MyDate,
      showNews: false,
      masterTube_banners: null,
      plan: { status: true },
      data: {
        mediaData: [],
        views: {
          status: false,
          series: [
            {
              name: "Views",
              data: [],
            },
          ],
          analyticsData: 0,
        },
        watchtime: {
          status: false,
          series: [
            {
              name: "WatchTime",
              data: [],
            },
          ],
          analyticsData: 0,
        },
        subscribers: {
          status: false,
          series: [
            {
              name: "Subscribers",
              data: [],
            },
          ],
          analyticsData: 0,
        },
        subscriberslost: {
          status: false,
          series: [
            {
              name: "Subscribers Lost",
              data: [],
            },
          ],
          analyticsData: 0,
        },
        like: {
          status: false,
          series: [
            {
              name: "Like",
              data: [],
            },
          ],
          analyticsData: 0,
        },
        dislike: {
          status: false,
          series: [
            {
              name: "Dislike",
              data: [],
            },
          ],
          analyticsData: 0,
        },
        share: {
          status: false,
          series: [
            {
              name: "Share",
              data: [],
            },
          ],
          analyticsData: 0,
        },
        comment: {
          status: false,
          series: [
            {
              name: "Comment",
              data: [],
            },
          ],
          analyticsData: 0,
        },
        timeline: {},
        userNews: [],
      },
      chartjsData,
      membership: {},
      plugins: [
        {
          beforeInit(chart) {
            chart.legend.afterFit = function () {
              this.height += 20;
            };
          },
        },
      ],
      countChannel: null,
      trans: {
        mode: "Wallet",
        types: "Starbucks",
        avatar: "PocketIcon",
        avatarVariant: "light-primary",
        payment: "-$74",
        deduction: true,
      },
      statusRequest: {
        getUserMembership: false,
        getUserRevenueChart: false,
        getViewData: false,
        getWatchTimeData: false,
        getSubscribersData: false,
        getSubscribersLostData: false,
        getLikesData: false,
        getDisLikesData: false,
        getSharesData: false,
        getCommentsData: false,
      },
      swiperOptions: {
        spaceBetween: 30,
        centeredSlides: true,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
    };
  },
  async created() {
    this.getAllBanners();
  },
  mounted() {
    this.getUnreadNotifications();
    this.refreshAll();
  },
  watch: {
    $route(to, from) {
      if (to.query != from.query) {
        this.$root.$emit("disableDates", false);
        this.refreshAll();
      }
    },
    "statusRequest.getUserMembership"(to, from) {
      this.checkStstusRequest();
    },
    "statusRequest.getUserRevenueChart"(to, from) {
      this.checkStstusRequest();
    },
    "statusRequest.getViewData"(to, from) {
      this.checkStstusRequest();
    },
    "statusRequest.getWatchTimeData"(to, from) {
      this.checkStstusRequest();
    },
    "statusRequest.getSubscribersData"(to, from) {
      this.checkStstusRequest();
    },
    "statusRequest.getSubscribersLostData"(to, from) {
      this.checkStstusRequest();
    },
    "statusRequest.getLikesData"(to, from) {
      this.checkStstusRequest();
    },
    "statusRequest.getDisLikesData"(to, from) {
      this.checkStstusRequest();
    },
    "statusRequest.getSharesData"(to, from) {
      this.checkStstusRequest();
    },
    "statusRequest.getCommentsData"(to, from) {
      this.checkStstusRequest();
    },
    "$i18n.locale"(next, prev) {
      this.getUnreadNotifications();
      this.getAllBanners();
    },
  },
  methods: {
    kFormatter,
    async getAllBanners() {
      const langInfo= await langInfoDao.getLangInfo()
      const en_banners = langInfo.en.images;
      const fa_banners = langInfo.fa.images;
      this.masterTube_banners =
        this.$i18n.locale == "fa" ? fa_banners : en_banners;
    },
    showModal() {
      this.$refs["my-modal"].show();
    },
    add() {
      this.$http.get("/api/channel/add").then((response) => {
        window.location.href = response.data;
      });
    },
    upgrade() {
      this.$router.push({ name: "Pricing" }).catch(() => {});
    },
    async getUserMembership() {
      this.$http.get("/api/dashboard/getUserPlan").then((response) => {
        this.plan = response.data;
        if (this.plan.status == false) {
          this.showModal();
        }
        if (response.data.status && response.data.days <= 7) {
          this.makeToast(
            this.$t("Base.Alert.Alert"),
            this.$t("Base.Alert.sub_end_new_it"),
            "warning"
          );
        }
        this.statusRequest.getUserMembership = true;
      });
    },
    async getUserRevenueChart() {
      this.$http
        .get("/api/dashboard/getUserRevenueChart", {
          params: {
            start: this.$route.query.start,
            end: this.$route.query.end,
          },
        })
        .then((response) => {
          this.chartjsData.lineChart.status = true;
          this.chartjsData.lineChart.data.labels = response.data.labels;
          this.chartjsData.lineChart.data.datasets[0].data =
            response.data.dataRevenue;
          this.chartjsData.lineChart.options.scales.yAxes[0].ticks.max =
            response.data.max;
          this.chartjsData.lineChart.total = response.data.total;
          this.statusRequest.getUserRevenueChart = true;
        });
    },
    async getViewData() {
      this.$http
        .get("/api/dashboard/getViewData", {
          params: {
            start: this.$route.query.start,
            end: this.$route.query.end,
          },
        })
        .then((response) => {
          this.data.views.status = true;
          this.data.views.series[0].data = response.data.data;
          this.data.views.analyticsData = response.data.total;
          this.statusRequest.getViewData = true;
        });
    },
    async getWatchTimeData() {
      this.$http
        .get("/api/dashboard/getWatchTimeData", {
          params: {
            start: this.$route.query.start,
            end: this.$route.query.end,
          },
        })
        .then((response) => {
          this.data.watchtime.status = true;
          this.data.watchtime.series[0].data = response.data.data;
          this.data.watchtime.analyticsData = response.data.total;
          this.statusRequest.getWatchTimeData = true;
        });
    },
    async getSubscribersData() {
      this.$http
        .get("/api/dashboard/getSubscribersData", {
          params: {
            start: this.$route.query.start,
            end: this.$route.query.end,
          },
        })
        .then((response) => {
          this.data.subscribers.status = true;
          this.data.subscribers.series[0].data = response.data.data;
          this.data.subscribers.analyticsData = response.data.total;
          this.statusRequest.getSubscribersData = true;
        });
    },
    async getSubscribersLostData() {
      this.$http
        .get("/api/dashboard/getSubscribersLostData", {
          params: {
            start: this.$route.query.start,
            end: this.$route.query.end,
          },
        })
        .then((response) => {
          this.data.subscriberslost.status = true;
          this.data.subscriberslost.series[0].data = response.data.data;
          this.data.subscriberslost.analyticsData = response.data.total;
          this.statusRequest.getSubscribersLostData = true;
        });
    },
    async getLikesData() {
      this.$http
        .get("/api/dashboard/getLikesData", {
          params: {
            start: this.$route.query.start,
            end: this.$route.query.end,
          },
        })
        .then((response) => {
          this.data.like.status = true;
          this.data.like.series[0].data = response.data.data;
          this.data.like.analyticsData = response.data.total;
          this.statusRequest.getLikesData = true;
        });
    },
    async getDisLikesData() {
      this.$http
        .get("/api/dashboard/getDisLikesData", {
          params: {
            start: this.$route.query.start,
            end: this.$route.query.end,
          },
        })
        .then((response) => {
          this.data.dislike.status = true;
          this.data.dislike.series[0].data = response.data.data;
          this.data.dislike.analyticsData = response.data.total;
          this.statusRequest.getDisLikesData = true;
        });
    },
    async getSharesData() {
      this.$http
        .get("/api/dashboard/getSharesData", {
          params: {
            start: this.$route.query.start,
            end: this.$route.query.end,
          },
        })
        .then((response) => {
          this.data.share.status = true;
          this.data.share.series[0].data = response.data.data;
          this.data.share.analyticsData = response.data.total;
          this.statusRequest.getSharesData = true;
        });
    },
    async getCommentsData() {
      this.$http
        .get("/api/dashboard/getCommentsData", {
          params: {
            start: this.$route.query.start,
            end: this.$route.query.end,
          },
        })
        .then((response) => {
          this.data.comment.status = true;
          this.data.comment.series[0].data = response.data.data;
          this.data.comment.analyticsData = response.data.total;
          this.statusRequest.getCommentsData = true;
        });
    },
    async refreshAll() {
      this.statusRequest = {
        getUserMembership: false,
        getUserRevenueChart: false,
        getViewData: false,
        getWatchTimeData: false,
        getSubscribersData: false,
        getSubscribersLostData: false,
        getLikesData: false,
        getDisLikesData: false,
        getSharesData: false,
        getCommentsData: false,
      };
      this.chartjsData.lineChart.status = false;
      this.data.views.status = false;
      this.data.watchtime.status = false;
      this.data.subscribers.status = false;
      this.data.subscriberslost.status = false;
      this.data.like.status = false;
      this.data.dislike.status = false;
      this.data.share.status = false;
      this.data.comment.status = false;
      await this.getUserMembership();
      await this.getUserRevenueChart();
      await this.getViewData();
      await this.getWatchTimeData();
      await this.getSubscribersData();
      await this.getSubscribersLostData();
      await this.getLikesData();
      await this.getDisLikesData();
      await this.getSharesData();
      await this.getCommentsData();
    },
    async getUnreadNotifications() {
      try {
        const response = await this.$http.get(
          "/api/user/getUserUnreadNotification"
        );
        this.showNews = true;
        this.data.userNews = response.data;
      } catch (error) {
        console.log("get unread notifications error:", error);
      }
    },
    checkStstusRequest() {
      for (let [key, value] of Object.entries(this.statusRequest)) {
        if (!value) {
          return false;
        }
      }
      this.$root.$emit("disableDates", true);
    },
    showAllNotifications() {
      this.$router.push({
        name: "notifications",
      });
    },
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}

.card-fix-size {
  height: 305px;
  overflow: auto;
}

.card-fix-size::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background: #2d2d2d;
  border-radius: 100%;

  .dark-layout & {
    background-color: $theme-dark-body-bg !important;
  }
}

/* Track */
.card-fix-size::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.card-fix-size::-webkit-scrollbar-thumb {
  border-radius: 0.5rem;
  background: rgba(241, 241, 241, 0.4);
}

.card-fix-size::-webkit-scrollbar-corner {
  display: none;
}

.max-width {
  width: 100%;
}

.max-height {
  max-height: 90px;
}

[dir] .card {
  margin-bottom: 1rem;
}
</style>
