import consoleService from "@/services/consoleService";
import { getLangInfoReq } from "../../requests/languages/getLangInfo";
class LangInfoDao {
  constructor() {
    if (!LangInfoDao.instance) {
      this.langInfoCache = {
        langInfo: null,
        refreshFlag: false,
      };
      LangInfoDao.instance = this;
    }
    return LangInfoDao.instance;
  }
  async getLangInfo() {
    try {
      if (!this.langInfoCache.langInfo || this.langInfoCache.refreshFlag) {
        const result = await getLangInfoReq();
        this.langInfoCache.langInfo = result;
        this.langInfoCache.refreshFlag = false;
      }
      return this.langInfoCache.langInfo;
    } catch (error) {
      consoleService.error("Error in getting lang info", error);
    }
  }
  forceRefreshLangInfoDao() {
    this.langInfoCache.refreshFlag = true;
  }
}

const langInfoDao = new LangInfoDao();
export { langInfoDao };
