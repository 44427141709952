import axiosIns from "@/libs/axios";
import consoleService from "@/services/consoleService";

export const getLangInfoReq=async ()=>{
    try {
      const response=await axiosIns.get('/api/v1/language');
      const {result}=response.data;
      return result;
    } catch (error) {
      consoleService.error("Error in getting language info", error);
    }
  }