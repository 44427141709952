const consoleService = {
  isEnabled: true,
  log(...args) {
    if (this.isEnabled) {
      console.log(...args);
    }
  },

  error(...args) {
    if (this.isEnabled) {
      console.error(...args);
    }
  },

  warn(...args) {
    if (this.isEnabled) {
      console.warn(...args);
    }
  },

};

export default consoleService;
